export * from './carousel'
export * from './what-to-expect'
export * from './tab'
export * from './accordion'

function toggleMonthlyAnnualPrice() {
  const toggleSwitch = document.querySelector('.levels .toggle .switch input[type=checkbox]')
  if (!toggleSwitch) {
    return
  }
  const price = document.querySelector('.levels .level.premium .price h2')
  const monthlyOrAnnual = document.querySelector('.levels .level.premium .price .frequency')
  toggleSwitch.addEventListener('change', (e) => {
    if (e.target.checked) {
      price.innerHTML = '4995'
      monthlyOrAnnual.innerHTML = '/year'
    } else {
      price.innerHTML = '595'
      monthlyOrAnnual.innerHTML = '/month'
    }
  })
}

document.addEventListener('DOMContentLoaded', () => {
  toggleMonthlyAnnualPrice()
})
